const killPageDefaultTransition = (keepOpacity = false) => {
	const transitionContainerEl = document.querySelector('.App__TransitionContainer');
	transitionContainerEl.classList.add('no-default-transition');
	if (keepOpacity === true) {
		transitionContainerEl.classList.add('keep-opacity');
	}
	window.addEventListener('nextPageLoadedEvent', () => {
		setTimeout(() => {
			transitionContainerEl.classList.remove('no-default-transition');
			if (keepOpacity === true) {
				transitionContainerEl.classList.remove('keep-opacity');
			}
		}, 1200)
	})
}

export default killPageDefaultTransition;