import React, { useContext, useState, useRef, useEffect } from "react"
import { StaticQuery, graphql, navigate } from 'gatsby';

import SplitText from '../splitText';
import killPageDefaultTransition from '../../helpers/killPageDefaultTransition';
import HoverImage from "../hoverImage";
import { ContextLoader } from '../../context'
import "./styles.scss";

const Menu = ({ data }) => {

	const links = data.prismic.allMenus.edges[0].node.links;

	const [isLoading, updateIsLoading] = useContext(ContextLoader);

	const [isOpen, updateIsOpen] = useState(false);
	const [currentLink, setCurrentLink] = useState(null);
	const [hoverImageToShow, setHoverImageToShow] = useState(null);
	const [hoverImagePosition, setHoverImagePosition] = useState(null);
	const linkEls = useRef([]);
	const exitEl = useRef(null);
	const homeEl = useRef(null);
	const hoverImageWrapperEl = useRef(null);

	const handleToggle = () => {
		if (isOpen === false) {
			exitEl.current.classList.add('enter-transition');
			homeEl.current.classList.add('enter-transition');
			setTimeout(() => {
				exitEl.current.classList.remove('enter-transition')
				homeEl.current.classList.remove('enter-transition')
			}, 1000);
		}
		updateIsOpen(!isOpen)
	}

	const handleLinkEnter = e => {
		// Reduce opacity for other links
		const currentLink = e.target.closest('.Menu__Link');
		setCurrentLink(currentLink);
		currentLink.classList.add('current');
		linkEls.current.map(link => link.classList.add('link-hover'))
		// Set hover image to show
		const newImageIndex = currentLink.getAttribute('data-hover-image-index');
		setHoverImageToShow(links[newImageIndex].link_imageSharp);
	}

	const handleLinkLeave = () => {
		// Keep the linkEnter state when mouse has been clicked
		if (hoverImagePosition !== null) return;
		// Reset styles for all links
		currentLink.classList.remove('current');
		linkEls.current.map(link => link.classList.remove('link-hover'))
		// Hide hover image
		setHoverImageToShow(null);
	}

	const handleClick = (e) => {

		const slug = e.target.closest('.Menu__Link').getAttribute('data-slug');

		// if device is touch
		if (localStorage.getItem('is-touch') === 'true') {
			// close menu
			updateIsOpen(false);
			// kill default page transition
			killPageDefaultTransition();
			// navigate
			navigate(`/${slug}`);
		}

		// if slug === current page close menu
		if (`/${slug}` === window.location.pathname) {
			updateIsOpen(false)
			return;
		}

		// send image to the left and keep it visible
		setHoverImagePosition('left');

		// do not appear the new page content before image is sent to the left
		updateIsLoading(true);

		// kill default page transition
		killPageDefaultTransition();

		// navigate
		navigate(`/${slug}`);

		// listen for route update (= next page loaded) to execute the rest of the animation
		window.addEventListener('nextPageLoadedEvent', () => {
			// close menu
			updateIsOpen(false)

			// Reset styles for all links
			currentLink.classList.remove('current');
			linkEls.current.map(link => link.classList.remove('link-hover'))

			setTimeout(() => {
				// reset hoverImage
				setHoverImagePosition(null);

				// appear new content
				updateIsLoading(false);

				// hide the fade out effect from hoverImage
				hoverImageWrapperEl.current.style.opacity = 0;
				handleLinkLeave();
				setTimeout(() => hoverImageWrapperEl.current.style.opacity = 1, 300)
			}, 1200)
		})
	}

	const handleHomeClick = () => {
		// if we already are on homepage, just close menu
		if (window.location.pathname === '/') {
			updateIsOpen(false)
			return;
		}
		// close menu
		updateIsOpen(false);
		// navigate
		navigate('/');
	}

	// update body class when isOpen state change
	useEffect(() => {
		if (isOpen === true) {
			document.querySelector('body').classList.add('menu-is-open');
		} else {
			document.querySelector('body').classList.remove('menu-is-open');
		}
	}, [isOpen]);

	return (
		<section className={`Menu ${isLoading ? 'is-loading' : ''} ${isOpen ? 'is-open' : ''}`}>

			<div
				className="Menu__Home"
				onClick={handleHomeClick}
				ref={homeEl}
				data-cursor-scale
			>
				<span className="Menu__HomeText"> Home </span>
				<span className="Menu__HomeText"> Home </span>
			</div>

			<div
				className="Menu__Enter"
				onClick={handleToggle}
				data-cursor-scale
			>
				<span className="Menu__EnterText"> – Menu </span>
				<span className="Menu__EnterText"> – Menu </span>
			</div>

			<div
				className="Menu__Exit"
				onClick={handleToggle}
				ref={exitEl}
				data-cursor-scale
			>
				<span className="Menu__ExitText"> – Exit </span>
				<span className="Menu__ExitText"> – Exit </span>
			</div>

			<div className="Menu__Content">

				<ul className="Menu__Links">
					{links.map((link, index) =>
						<li
							className="Menu__Link"
							ref={ref => linkEls.current[index] = ref}
							onMouseEnter={handleLinkEnter}
							onMouseLeave={handleLinkLeave}
							onClick={handleClick}
							key={`menu-link-${index}`}
							data-hover-image-index={index}
							data-slug={link.slug[0].text}
							data-cursor-scale
						>
							<span className="Menu__LinkText"> <SplitText splitBy="chars"> {link.default_text[0].text} </SplitText></span>
							<span className="Menu__LinkText"> <SplitText splitBy="chars"> {link.hover_text[0].text} </SplitText></span>
						</li>
					)}
				</ul>

				<div ref={hoverImageWrapperEl}>
					<HoverImage imageToShow={hoverImageToShow} position={hoverImagePosition} />
				</div>

			</div>

			<div className="Menu__Layer"></div>

		</section>
	)
}

export default () => (
	<StaticQuery
		query={graphql`
			query MenuQuery {
				prismic {
					allMenus {
						edges {
							node {
								links {
									hover_text
									default_text
									slug
									link_image
									link_imageSharp {
										childImageSharp {
											fluid(maxWidth: 2000) {
												...GatsbyImageSharpFluid
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => (
			<Menu data={data} />
		)}
	/>
)