import React, { useEffect, useRef } from 'react';
import { TweenMax, Power2 } from 'gsap';
import './styles.scss';

const Cursor = () => {

	const cursorEl = useRef(null);

	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove)
		return function clean() {
			window.removeEventListener('mousemove', handleMouseMove);
		}
	});

	const handleMouseMove = e => {
		let isHovering = false;
		if (e.target.closest('[data-cursor-scale]') !== null) {
			isHovering = true;
		}
		TweenMax.to(cursorEl.current, 0.3, {
			x: e.clientX,
			y: e.clientY,
			scale: isHovering ? 2 : 1,
			ease: Power2.ease
		});
	}

	return (
		<>
			<div className="Cursor" ref={cursorEl}></div>
		</>
	)

}

export default Cursor;