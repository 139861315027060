import React, {useState, useEffect, useRef, useContext} from 'react';

import { ContextLoader } from '../../context';
import loadFonts from '../../helpers/loadFonts';

import './styles.scss';

const Loader = () => {

	const [isLoading, updateIsLoading] = useContext(ContextLoader);

	const [isHidden, updateIsHidden] = useState(false);
	const [percent, updatePercent] = useState(0);
	const [step, updateStep] = useState(0);
	const [canUpdatePercent, setCanUpdatePercent] = useState(false);

	const containerEl = useRef(null);
	const maskEl = useRef(null);
	const maskTextEl = useRef(null);

	let images = [];

	// General
	useEffect(() => {
		document.body.classList.add('is-loading');
	}, [])


	// General loading handler
	useEffect(() => {
		// First load fonts
		handleFontLoad().then(() => {
			// Appear texts
			containerEl.current.classList.add('appear');
			// Launch images load and text fill
			setTimeout(() => {
				// Launch percent animation
				setTimeout(() => {
					setCanUpdatePercent(true);
				}, 650)
				loadImages().then(() => {
					setTimeout(() => {
						containerEl.current.classList.add('leave');
						document.body.classList.remove('is-loading');
					}, 1400)
					setTimeout(() => {
						updateIsHidden(true);
						updateIsLoading(false);
					}, 2800);
				})
			}, 1000)
		})
	}, [])

	// Load images
	const loadImages = () => {
		images = [...document.querySelectorAll('.gatsby-image-wrapper > img')];
		const stepRatio = 1 / images.length;
		let imagesLoadedCount = 0;
		return new Promise((resolve, reject) => {
			if (images.length === 0) {
				updateProgress(1);
				resolve();
				return;
			}
			images.map(img => {
				loadImage(img).then(imgLoaded => {
					imagesLoadedCount += 1;
					const nextstep = stepRatio * imagesLoadedCount;
					updateProgress(nextstep);
					if (imagesLoadedCount === images.length) {
						resolve();
					}
				})
			})
		})
	};

	const loadImage = (img) => {
		return new Promise(
			(resolve) => {
				// If image is already loaded
				if (img.complete === true) {
					resolve(img);
				}
				// Add event listeners
				img.addEventListener('load', () => {
					resolve(img);
				});
			},
		);
	}

	// Load fonts
	const handleFontLoad = () => {
		return new Promise((resolve, reject) => {
			loadFonts().then(() => {
				resolve();
			});
		})
	};

	const updateProgress = (nextStep) => {
		// Set step state
		updateStep(nextStep);
		// Update text filling
		const nextStepPercent = nextStep * 100;
		maskEl.current.style.transform = `translate3d(0, ${100 - nextStepPercent}%, 0)`
		maskTextEl.current.style.transform = `translate3d(0, -${100 - nextStepPercent}%, 0)`
	}

	// Number progress
	useEffect(() => {
		let interval = null;
		interval = setInterval(() => {
			if (percent < step && canUpdatePercent === true)
				updatePercent(percent + 0.01);
			else if (percent >= 1)
				clearInterval(interval);
		}, 5)
		return function stop() {
			clearInterval(interval);
		}
	})

	return (
		<div className={`Loader ${isHidden ? 'hidden' : ''}`} ref={containerEl}>
			<div className="Loader__Percent">
				<span className="Loader__PercentText"> – {Math.floor(percent * 100)}% </span>
			</div>
			<div className="Loader__Container">
				<div className="Loader__Content">
					<div className="Loader__Text">
						<div className="Loader__Line">
							<span className="Loader__LineText"> Tee </span>
						</div>
						<div className="Loader__Line">
							<span className="Loader__LineText"> Tran </span>
						</div>
						<div className="Loader__Line">
							<span className="Loader__LineText"> Visual </span>
						</div>
						<div className="Loader__Line">
							<span className="Loader__LineText"> Designer </span>
						</div>
					</div>
					<div className="Loader__Mask" ref={maskEl}>
						<div className="Loader__Text Loader__Text--Mask" ref={maskTextEl}>
							<div className="Loader__Line Loader__Line--Mask">
								<span className="Loader__LineText">Tee</span>
							</div>
							<div className="Loader__Line Loader__Line--Mask">
								<span className="Loader__LineText">Tran</span>
							</div>
							<div className="Loader__Line Loader__Line--Mask">
								<span className="Loader__LineText">Visual</span>
							</div>
							<div className="Loader__Line Loader__Line--Mask">
								<span className="Loader__LineText">Designer</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Loader;