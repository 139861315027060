import React, { useState } from "react";

import Loader from "../components/loader";
import Menu from "../components/menu";
import Transition from '../components/transition'
import Cursor from '../components/cursor'

import { ContextLoader } from '../context';

import './styles.scss'

if (typeof window !== `undefined`) {
	// Set local storage is-touch
	if ("ontouchstart" in document.documentElement) {
		localStorage.setItem('is-touch', 'true');
		document.querySelector('html').classList.add('is-touch');
	} else {
		localStorage.setItem('is-touch', 'false');
	}

	// 100vh bug for phones
	window.onresize = function () {
		// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
		let vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
	window.onresize();

	// Create event that's gonna be triggered when the next page is loaded on a link click
	function createNewEvent(eventName) {
		var event;
		if (typeof(Event) === 'function') {
			event = new Event(eventName);
		} else {
			event = document.createEvent('Event');
			event.initEvent(eventName, true, true);
		}
		return event;
	}
	// Create event that's gonna be triggered when the next page is loaded on a link click
	window.nextPageLoadedEvent = createNewEvent('nextPageLoadedEvent');
	// Transform locomotive scroll event to global event
	// window.customScrollEvent = createNewEvent('customScrollEvent');
	// Stop and start locomotive scroll event
	window.stopScrollEvent = createNewEvent('stopScrollEvent');
	window.startScrollEvent = createNewEvent('startScrollEvent');
}

const Layout = ({ children, location }) => {

	const [isLoading, updateIsLoading] = useState(true);

	return (
		<>
			<ContextLoader.Provider value={[isLoading, updateIsLoading]}>
				<div className="App">
					<Loader />
					<Cursor />
					<Menu />
					<div className="App__TransitionContainer">
						<Transition location={location}>
							{children}
						</Transition>
					</div>
				</div>
			</ContextLoader.Provider>
		</>
	)

}

export default Layout;