import React from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import LocalImage from '../localImage';

import './styles.scss';

const timeout = 1000;

class Transition extends React.PureComponent {

	constructor(props) {
		super(props);
		this.transitionLayerEl = React.createRef();
		this.handleExit = this.handleExit.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
	}

	handleExit() {
		this.transitionLayerEl.current.classList.add('visible');
	}

	handleEnter() {
		this.transitionLayerEl.current.classList.remove('visible');
		this.transitionLayerEl.current.classList.add('leaving');
		setTimeout(() => {
			this.transitionLayerEl.current.classList.remove('leaving');
		}, 800)
	}

  render() {
		const { children, location } = this.props

    return (
			<>
				<SwitchTransition mode="out-in">
					<CSSTransition
						key={location.pathname}
						timeout={timeout}
						onExit={this.handleExit}
						onEnter={this.handleEnter}
					>
						{children}
					</CSSTransition>
				</SwitchTransition>
				<div className="TransitionLayer" ref={this.transitionLayerEl}>
					<LocalImage filename="Transition-Image.png" />
				</div>
			</>
    )
  }
}
export default Transition