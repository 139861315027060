/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Register link resolver
const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');
registerLinkResolver(linkResolver);

// Send an event when next page is ready
export const onRouteUpdate = () => {
	window.dispatchEvent(window.nextPageLoadedEvent);
}