import React, { useRef, useEffect, useState } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Img from 'gatsby-image';

import { lerp, mapRange } from '../../helpers';
import './styles.scss';

const HoverImage = ({ imageToShow = null, position = null, className }) => {

	const containerEl = useRef(null);
	const followMouseEl = useRef(null);

	const [mouseCoords, setMouseCoords] = useState({x: null, y: null});
	const [deltaPos, setDeltaPos] = useState({x: null, y: null});

	let rafId = null;
	let halfImgWidth = 0;
	let halfImgHeight = 0;
	let heightRangeMin = 0;
	let heightRangeMax = 0;

	useEffect(() => {
		window.addEventListener('resize', onResize);
		onResize();
		if (localStorage.getItem('is-touch') === 'false') {
			window.addEventListener('mousemove', handleMouseMove);
			rafId = requestAnimationFrame(loop);
		}
		return function clean () {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('resize', onResize);
			cancelAnimationFrame(rafId);
		}
	})

	useEffect(() => {
		if (position === 'left') {
			// borders
			const border = window.innerWidth * 0.01388888889;
			setMouseCoords({
				x: border + halfImgWidth,
				y: border + halfImgHeight
			});
		}
	}, [position])

	const handleMouseMove = e => {
		if (position !== null) return;
		setMouseCoords({x: e.clientX, y: e.clientY});
	}

	const loop = () => {
		rafId = requestAnimationFrame(loop);

		const targetX = mouseCoords.x;
		const targetY = mapRange(mouseCoords.y, window.innerHeight, 0, heightRangeMax, heightRangeMin);

		const newDeltaPosX = lerp(deltaPos.x, targetX - halfImgWidth, 0.1);
		const newDeltaPosY = lerp(deltaPos.y, targetY - halfImgHeight, 0.1);

		setDeltaPos({x: newDeltaPosX, y: newDeltaPosY});

		followMouseEl.current.style.transform = `translate3d(${deltaPos.x}px, ${deltaPos.y}px, 0)`;
	}

	const onResize = () => {
		const followElRect = followMouseEl.current.getBoundingClientRect();
		halfImgWidth = followElRect.width * 0.5;
		halfImgHeight = followElRect.height * 0.5;
		const heightRange = window.innerHeight * 0.5;
		heightRangeMin = (window.innerHeight - heightRange) * 0.5;
		heightRangeMax = heightRangeMin + heightRange;
		if (mouseCoords.x === null && mouseCoords.y === null) {
			setMouseCoords({x: window.innerWidth * 0.5, y: window.innerHeight * 0.5});
		}
	}

	return (
		<div className={`HoverImage ${position || ''} ${className || ''}`} ref={containerEl}>
			<div
				className={`HoverImage__FollowMouse ${imageToShow ? 'visible' : ''}`}
				ref={followMouseEl}
			>
				<div className="HoverImage__Scale">
					<SwitchTransition mode="in-out">
						{imageToShow &&
							<CSSTransition key={imageToShow} timeout={2000} classNames="hoverimage-transition">
								<div className="HoverImage__Transition">
									<Img fluid={imageToShow.childImageSharp.fluid} />
								</div>
							</CSSTransition>
						}
					</SwitchTransition>
				</div>
			</div>
		</div>
	)

}

export default HoverImage;