function lerp(v0, v1, t) {
  return v0*(1-t)+v1*t
}

function mapRange(value, low1, high1, low2, high2) {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}

export {
	lerp,
	mapRange
};