const FontFaceObserver = require('fontfaceobserver');

const fontsData = {
  'Agrandir Grand': { weight: 900 },
  'Scto Grotesk A': { weight: 'normal' },
  'Moret-Book': { weight: 300 },
};

const defaultFonts = [
  'Agrandir Grand',
	'Scto Grotesk A',
	'Moret-Book'
]

function loadFonts(fonts = defaultFonts) {
  return new Promise((resolve) => {
    const fontFaceObersvers = fonts.map(font => {
      const data = fontsData[font];
      return new FontFaceObserver(font, data);
    })
    Promise.all(fontFaceObersvers).then(() => {
      resolve();
    });
  })
}

export default loadFonts;